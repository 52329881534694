body {
    background-color: #101010;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; 
}

div.content {
    margin-left: 5vw;
    margin-right: 5vw;
}

h1 {
    margin-top: 16vh;
    margin-bottom: 1vh;
    color: white;
    font-size: max(min(6vw, 100pt), 6vh);

}

@keyframes wheelHueColor {
    from, to { color: #ff6961; }
    12.5%      { color: #ffb480; }
    25%      { color: #f8f38d; }
    37.5%      { color: #42d6a4; }
    50%      { color: #08cad1; }
    62.5%      { color: #59adf6; }
    85%      { color: #9d94ff; }
    97.5%      { color: #c780e8; }
}

.rotateColor {
    color: #ffffff;
    animation: wheelHueColor 30s infinite;
}

h2 {
    font-size: max(min(2vw, 20pt), 2vh);
    color: #ffffff;
    margin-top: 8vh;
}

h3 {
    font-size: max(min(1.5vw, 18pt), 1.5vh);
    color: #ffffff;
}

p {
    font-size: max(min(1.3vw, 16pt), 1.3vh);
    color: #808080;
    margin-top: 1vh;
}

a:-webkit-any-link {
    text-decoration: none;
    color: #b1b1b1;
}

.navImage {
    display: block;
    margin: 0 auto;
    width: 2vw;
    @media screen and (max-width: 768px) {
        width: 30%;
    }
}